import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/application",
  },
  {
    path: "/application",
    name: "Application",
    component: () => import(/* webpackChunkName: "about" */ "../views/Application.vue"),
  },
  {
    path: "/application/:productTypeId",
    name: "ApplicationRefi",
    component: () => import(/* webpackChunkName: "about" */ "../views/ApplicationRefi.vue"),
  },
  {
    path: "/invite/accept",
    name: "Invite",
    component: () => import(/* webpackChunkName: "about" */ "../components/application/InvitedApplicant.vue"),
  },
  {
    path: "/return",
    name: "Return",
    component: () => import(/* webpackChunkName: "about" */ "../views/Return.vue"),
  },
  {
    path: "/ecommunications",
    name: "ecommunications",
    component: () => import(/* webpackChunkName: "protected" */ "../views/ECommunications.vue"),
    meta: {
      isPublic: true,
      title: "ECommunications",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "protected" */ "../views/TermsAndConditions.vue"),
    meta: {
      isPublic: true,
      title: "Terms & Conditions",
    },
  },
  {
    path: "/mobileCommunications",
    name: "mobileCommunications",
    component: () => import(/* webpackChunkName: "protected" */ "../views/MobileCommunications.vue"),
    meta: {
      isPublic: true,
      title: "Mobile Communications",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import(/* webpackChunkName: "protected" */ "../views/PrivacyPolicy.vue"),
    meta: {
      isPublic: true,
      title: "Privacy Policy",
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () => import(/* webpackChunkName: "protected" */ "../views/Accessibility.vue"),
    meta: {
      isPublic: true,
      title: "Accessibility",
    },
  },
	  {
    path: "/federalLoanDisclosure",
    name: "federalLoanDisclosure",
    component: () => import(/* webpackChunkName: "protected" */ "../views/FederalLoanDisclosure.vue"),
    meta: {
      isPublic: true,
      title: "Federal Loan Disclosure",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  console.log("router beforeEach", { to, from, store });
  // #TODO figure out how we will prevent users from changing productTypeIds
});

export default router;
