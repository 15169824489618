import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import useFormatter from "@/use/formatter";
import useArrayHelpers from "@/use/arrayHelpers";
import _ from "lodash";

const { formatNumber } = useFormatter();
const { groupBy } = useArrayHelpers();

const refiRatesSelfSelectModule: Module<any, any> = {
  namespaced: true,
  state: {
    primaryApplicantSelectedCreditScoreRange: {},
    coApplicantSelectedCreditScoreRange: {},
    selectedCreditScoreRange: {},
    currentApplicantTypeId: 0 as number,
    currentApplicantTypeName: "" as string,
    sortedAvailableRateOptions: {} as any,
    sortedAvailableTerms: [] as any[],
    availableRateOptions: [] as any[],
    creditScoreRanges: [] as any[],
    fixedRateCreditScoreRanges: [] as any[],
    variableRateCreditScoreRanges: [] as any[],
  },
  mutations: {
    SET_SELECTED_CREDIT_SCORE_RANGE(state: any, selectedCreditScoreRange: any) {
      state.selectedCreditScoreRange = selectedCreditScoreRange;
    },
    SET_CURRENT_APPLICANT_TYPE_ID(state: any, currentApplicantTypeId: number) {
      state.currentApplicantTypeId = currentApplicantTypeId;
    },
    SET_CURRENT_APPLICANT_TYPE_NAME(state: any, currentApplicantTypeName: string) {
      state.currentApplicantTypeName = currentApplicantTypeName;
    },
    SET_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.creditScoreRanges = creditScoreRanges;
    },
    SET_FIXED_RATE_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.fixedRateCreditScoreRanges = creditScoreRanges;
    },
    SET_VARIABLE_RATE_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.variableRateCreditScoreRanges = creditScoreRanges;
    },
    RESET_ALL_STATES(state: any) {
      state.primaryApplicantSelectedCreditScoreRange = {};
      state.coApplicantSelectedCreditScoreRange = {};
      state.selectedCreditScoreRange = {};
      state.currentApplicantTypeId = 0;
      state.currentApplicantTypeName = "";
      state.sortedAvailableRateOptions = {};
      state.sortedAvailableTerms = [];
      state.availableRateOptions = [];
      state.creditScoreRanges = [];
			state.fixedRateCreditScoreRanges = [];
			state.variableRateCreditScoreRanges = [];
    },
  },
  actions: {
    async getCreditScoreRanges({ state, commit }, payload) {
      await store.dispatch("getProgram");
      if (store.state.program?.pricingTiers?.length > 0) {
        return store?.state?.program?.pricingTiers
          .filter((i: any) => i.applicantType.id === state.currentApplicantTypeId)
          .filter((i: any) => i.rate.rateType.name === payload)
          .map((option: any) => {
            return {
              rateTypeName: option.rate.rateType.name,
              min: option.ficoMin,
              max: option.ficoMax,
            };
          })
          .sort(function (a: any, b: any) {
            return a.min - b.min;
          });
      } else {
        return [];
      }
    },
  },
  getters: {
    SortedAvailableRateOptions: (state: any): any => {
      if (store?.state?.program?.pricingTiers?.length > 0) {
        const availableRates = store?.state?.program?.pricingTiers
          .filter((i: any) => i.applicantType.id === state.currentApplicantTypeId)
          .filter((k: any) => k.ficoMin === state.selectedCreditScoreRange?.min)
          .map((option: any) => {
            // Fixed Rate
            if (option.rate.rateType.name === "Fixed") {
              const fixedInterestRate = option.rate.margin / 100;

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: fixedInterestRate,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber(fixedInterestRate * 100)}%`,
              };
            } else if (option.rate.rateType.name === "Variable") {
              const indexRate = option.rate.indexType.rate;
              const variableRate = option.rate.margin + indexRate;

              let interestRate = 0 as number;
              if (variableRate < option.rate.floor) {
                interestRate = option.rate.floor;
              } else if (variableRate > option.rate.ceiling) {
                interestRate = option.rate.ceiling;
              } else {
                interestRate = variableRate;
              }

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: interestRate / 100,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber((interestRate / 100) * 100)}%`,
              };
            } else
              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: option.rate.margin / 100,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber((option.rate.margin / 100 / 100) * 100)}%`,
              };
          })
          .sort((a: any, b: any) => (a.rateTypeName > b.rateTypeName ? 1 : b.rateTypeName > a.rateTypeName ? -1 : 0));
        return groupBy(availableRates, "termInYears");
      } else {
        return [];
      }
    },
    AvailableCreditScoreRanges: (state: any): any => {},
    AvailableRateTermOptions: (state: any): any => {},
    AvailableRateOptions: (state: any): any => {
      if (store?.state?.program?.pricingTiers?.length > 0) {
        return store?.state?.program?.pricingTiers
          .filter((i: any) => i.applicantType.id === state.currentApplicantTypeId)
          .filter((k: any) => k.ficoMin === state.selectedCreditScoreRange?.min)
          .map((option: any) => {
            // Fixed Rate
            if (option.rate.rateType.name === "Fixed") {
              const fixedInterestRate = option.rate.margin / 100;

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: fixedInterestRate,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber(fixedInterestRate * 100)}%`,
              };
            } else if (option.rate.rateType.name === "Variable") {
              const indexRate = option.rate.indexType.rate;
              const variableRate = option.rate.margin + indexRate;

              let interestRate = 0 as number;
              if (variableRate < option.rate.floor) {
                interestRate = option.rate.floor;
              } else if (variableRate > option.rate.ceiling) {
                interestRate = option.rate.ceiling;
              } else {
                interestRate = variableRate;
              }

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: interestRate / 100,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber((interestRate / 100) * 100)}%`,
              };
            } else
              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: option.rate.margin / 100,
                rateTypeName: option.rate.rateType.name,
                formattedInterestRate: `${formatNumber((option.rate.margin / 100 / 100) * 100)}%`,
              };
          })
          .sort((a: any, b: any) => (a.rateTypeName > b.rateTypeName ? 1 : b.rateTypeName > a.rateTypeName ? -1 : 0));
      }
      return [];
    },
  },
};
export default refiRatesSelfSelectModule;
