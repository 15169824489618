import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import { CandidateRefiLoan } from "@/models/application";

export interface Notification {
  error: boolean;
  message: string;
}

const candidateLoansRefiModule: Module<any, any> = {
  namespaced: true,
  state: {
    applicationId: 0 as number,
    currentlyEditingIds: [] as number[],
    originalCandidateRefiLoans: {} as any,
    addingLoan: false,
    servicers: [],
    candidateRefiLoans: [] as CandidateRefiLoan[],
    notifications: [] as Notification[],
    mockLoansData1: [
      {
        applicationId: 0,
        id: 1,
        accountNumber: 36002195,
        creditorName: "A",
        servicerId: 2,
        balance: 3135,
        include: false,
        tradeLineId: 1,
      },
      {
        applicationId: 0,
        id: 2,
        accountNumber: 21313213,
        creditorName: "B",
        balance: 5066,
        include: false,
        tradeLineId: 2,
        servicerId: 2,
      },
      {
        applicationId: 0,
        id: 3,
        accountNumber: 11122233,
        creditorName: "C",
        balance: 11122,
        include: false,
        tradeLineId: 3,
        servicerId: 3,
      },
      {
        applicationId: 0,
        id: 4,
        accountNumber: 11122235,
        creditorName: "C",
        balance: 11129,
        include: false,
        tradeLineId: 4,
        servicerId: 3,
      },
      {
        applicationId: 0,
        id: 5,
        accountNumber: 2222222,
        creditorName: "C",
        balance: 5000,
        include: true,
        tradeLineId: null,
        servicerId: 3,
      },
      {
        applicationId: 0,
        id: 6,
        accountNumber: 5555555,
        creditorName: "C",
        balance: 5000,
        include: true,
        tradeLineId: null,
        servicerId: 3,
      },
      {
        applicationId: 0,
        id: 7,
        accountNumber: 7777771,
        creditorName: "C",
        balance: 5000,
        include: true,
        tradeLineId: null,
        servicerId: 3,
      },
      {
        applicationId: 0,
        id: 8,
        accountNumber: 22225555,
        creditorName: "My lender / servicer isn't listed",
        balance: 5000,
        include: true,
        tradeLineId: null,
        servicerId: 1,
      },
      // {
      //applicationId: 0,
      //   id: 6,
      //   accountNumber: 8888810,
      //   creditorName: "My lender / servicer isn't listed",
      //   balance: 5000,
      //   include: false,
      //  servicerId: 1,
      // },
    ],
    mockLoansData: [],
    candidateRefiLoanToAdd: {} as any,
  },
  mutations: {
    ADD_NOTIFICATION(state: any, notification: Notification) {
      state.notifications.push(notification);
    },
    DELETE_NOTIFICATION(state: any, index: number) {
      state.notifications.splice(index, 1);
    },
    SET_APPLICATION_ID(state: any, applicationId: number) {
      state.applicationId = applicationId;
    },
    SET_SERVICERS(state: any, servicers: any) {
      state.servicers = servicers;
    },
    SET_CANDIDATE_REFI_LOANS(state: any, loans: Array<CandidateRefiLoan>) {
      state.candidateRefiLoans = loans;
    },
    SET_CANDIDATE_REFI_LOAN(state: any, candidateRefiLoan: CandidateRefiLoan) {
      const index = state.candidateRefiLoans.findIndex((loan: any) => loan.id === candidateRefiLoan.id);
      if (index > -1) {
        state.candidateRefiLoans[index] = candidateRefiLoan;
      }
    },
    SET_ORIGINAL_CANDIDATE_REFI_LOANS(state: any, loans: any) {
      state.originalCandidateRefiLoans = loans;
    },
    SET_ORIGINAL_CANDIDATE_REFI_LOAN(state: any, candidateRefiLoan: CandidateRefiLoan) {
      state.originalCandidateRefiLoans[candidateRefiLoan.id] = Object.assign({}, candidateRefiLoan);
    },
    SET_MOCK_CANDIDATE_REFI_LOANS(state: any, loans: any) {
      state.mockLoansData = loans;
    },
    SET_MOCK_CANDIDATE_REFI_LOAN(state: any, updatedLoan: any) {
      const index = state.mockLoansData.findIndex((loan: any) => loan.id === updatedLoan.id);

      if (index > -1) {
        state.mockLoansData[index] = updatedLoan;
      }
    },
    ADD_MOCK_CANDIDATE_REFI_LOAN(state: any, loan: any) {
      state.mockLoansData.push(loan);
    },
    DELETE_MOCK_CANDIDATE_REFI_LOAN(state: any, id: number) {
      const index = state.mockLoansData.findIndex((l: any) => l.id === id);
      if (index > -1) {
        state.mockLoansData.splice(index, 1);
      }
    },
    DISCARD_CHANGES(state: any, candidateRefiLoanId: number) {
      const originalCandidateRefiLoan = state.originalCandidateRefiLoans[candidateRefiLoanId];
      const index = state.candidateRefiLoans.findIndex((loan: any) => loan.id === candidateRefiLoanId);
      if (index > -1) {
        state.candidateRefiLoans[index] = originalCandidateRefiLoan;
      }

      if (Object.keys(state.originalCandidateRefiLoans)?.includes(`${candidateRefiLoanId}`)) {
        delete state.originalCandidateRefiLoans[candidateRefiLoanId];
      }
    },
    TOGGLE_EDIT(state: any, candidateRefiLoanId: number) {
      const index = state.currentlyEditingIds.findIndex((id: number) => id === candidateRefiLoanId);

      if (index === -1) {
        state.currentlyEditingIds.push(candidateRefiLoanId);
      }
    },
    UNTOGGLE_EDIT(state: any, candidateRefiLoanId: number) {
      const index = state.currentlyEditingIds.findIndex((id: number) => id === candidateRefiLoanId);

      if (index > -1) {
        state.currentlyEditingIds.splice(index, 1);
      }
    },
    SET_ADDING_LOAN(state: any, flag: boolean) {
      state.addingLoan = flag;
    },
  },
  actions: {
    async getServicers({ state, commit }) {
      if (state.servicers?.length <= 0) {
        const response = await $api.refi.getServicers();
        if (response?.length > 0) {
          commit("SET_SERVICERS", response);
        }
      }
    },
    async getCandidateRefiLoans({ commit }, applicationId: number) {
      const response = await $api.refi.getCandidateRefiLoans(applicationId);
      if (response?.length > 0) {
        commit("SET_CANDIDATE_REFI_LOANS", response);
      }
    },
    setCandidateRefiLoans({ commit }, candidateRefiLoans: any) {
      commit("SET_CANDIDATE_REFI_LOANS", candidateRefiLoans);
    },
    setOriginalCandidateRefiLoans({ commit }, candidateRefiLoans: any) {
      commit("SET_ORIGINAL_CANDIDATE_REFI_LOANS", candidateRefiLoans);
    },
    setCandidateRefiLoan({ commit }, candidateRefiLoan: CandidateRefiLoan) {
      commit("SET_CANDIDATE_REFI_LOAN", candidateRefiLoan);
    },
    closeNotification({ commit }, index: number) {
      commit("DELETE_NOTIFICATION", index);
    },
    addNotification({ commit }, notification: Notification) {
      commit("ADD_NOTIFICATION", notification);
    },
    setApplicationId({ commit }, applicationId: number) {
      commit("SET_APPLICATION_ID", applicationId);
    },
    addMockCandidateRefiLoan({ commit }, payload: any) {
      commit("ADD_MOCK_CANDIDATE_REFI_LOAN", payload);
    },
    deleteMockCandidateRefiLoan({ commit }, payload: any) {
      commit("DELETE_MOCK_CANDIDATE_REFI_LOAN", payload);
    },
    setMockCandidateRefiLoans({ commit }, payload) {
      commit("SET_MOCK_CANDIDATE_REFI_LOANS", payload);
    },
    setMockCandidateRefiLoan({ commit }, payload) {
      commit("SET_MOCK_CANDIDATE_REFI_LOAN", payload);
    },
    toggleEdit({ commit }, candidateRefiLoanId: number) {
      commit("TOGGLE_EDIT", candidateRefiLoanId);
    },
    untoggleEdit({ commit }, candidateRefiLoanId: number) {
      commit("UNTOGGLE_EDIT", candidateRefiLoanId);
    },
    isEditing({ state }, candidateRefiLoanId: number) {
      return state.currentlyEditingIds?.includes(candidateRefiLoanId) || false;
    },
    setAddingLoan({ commit }, flag: boolean) {
      commit("SET_ADDING_LOAN", flag);
    },
    discardChanges({ commit }, candidateRefiLoanId: number) {
      commit("DISCARD_CHANGES", candidateRefiLoanId);
    },
    getSucccessNotificationMessage({ commit }, action: string) {
      switch (action) {
        case "ADD":
          return "The information has been added.";
        case "EDIT":
        case "UPDATE":
          return "The information has been updated.";
        case "DELETE":
          return "The information has been removed.";
        default:
          return "";
      }
    },
    getErrorNotificationMessage({ commit }, action: string) {
      switch (action) {
        case "ADD":
        case "EDIT":
        case "UPDATE":
          return "Unable to update. Please try again.";
        case "DELETE":
          return "Unable to remove. Please try again.";
        default:
          return "";
      }
    },
    async handleNotification({ commit, dispatch }, payload: any) {
      const notification = {
        error: payload.error,
        message: payload.error
          ? await dispatch("getErrorNotificationMessage", payload.action)
          : await dispatch("getSucccessNotificationMessage", payload.action),
      };

      dispatch("addNotification", notification);
    },
    calculateEstimatedTotalToRefinance({ state }) {
      if (state?.candidateRefiLoans?.length > 0) {
        return state?.candidateRefiLoans?.reduce(function (acc: any, obj: any) {
          const bal = typeof obj.balance === "string" ? parseFloat(obj.balance) : obj.balance;

          return obj.include ? acc + bal : acc;
        }, 0);
      } else {
        return 0;
      }
    },
  },
  getters: {},
};

export default candidateLoansRefiModule;
